import {FlatList, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {StatusBar} from "expo-status-bar";
import NavWrapper from "../components/NavWrapper";
import {styles as MainStyles} from "../components/MainStyles";
import firebase, {getUserData, listPacks, listPacksLive} from "../config/firebase";
import {useEffect, useState} from "react";
import {addDoc, collection, deleteDoc, doc, getFirestore, setDoc} from "firebase/firestore";
import {EditableStrip} from "../components/EditableStrip";
import {getAuth} from "firebase/auth";
import {CardTypes, convertor, PackCardDocument, PackDocument} from "../config/types";
import {useFirestoreNavigatorLive} from "../hooks/useFirstoreDocumentLive";
import type {StackScreenProps} from "@react-navigation/stack";
import {RootStackParamList} from "../../App";

const firestore = getFirestore(firebase)

export default function PackManager(props: StackScreenProps<RootStackParamList, "Pack Manager"> | StackScreenProps<RootStackParamList, "Pack Manager Specific">) {
    const packs = listPacksLive(true)
    const cards = useFirestoreNavigatorLive<PackCardDocument<any>>((a, b) => {
        return a.data().type < b.data().type ? 1 : -1
    })
    const [selectedPack, setSelectedPack] = useState<string>()
    const [focusCard, setFocusCard] = useState<string | null>(null)

    const refreshFirstoreNavigator = (id = selectedPack) => {
        cards.setQuery(collection(firestore, "packs", id, "cards"))
    }

    useEffect(() => {
        if (props.route.params?.id) {
            setSelectedPack(props.route.params.id)
            // refreshFirstoreNavigator(props.)
        }
    }, []);

    useEffect(() => {
        if (selectedPack) {
            refreshFirstoreNavigator()
        } else {
            cards.clear()
        }
    }, [selectedPack]);

    return (
        <NavWrapper navigation={props.navigation} style={{flexGrow: 1}}>
            <View style={[MainStyles.container, {flexGrow: 0, padding: 40, backgroundColor: "#d911cb"}]}>
                <Text style={{fontWeight: "bold", fontSize: 24, color: "#fff"}}>Manage your packs</Text>
                <StatusBar style="auto"/>
            </View>

            <View
                style={{
                    flexDirection: "row",
                    flexShrink: 1,
                    flexGrow: 1,
                    alignItems: "flex-start",
                    overflow: "hidden",
                    height: 10
                }}
            >
                <View
                    style={{
                        width: 300,
                        padding: 20,
                        backgroundColor: "#eee",
                        flexDirection: "column",
                        overflow: "scroll",
                        height: "100%",
                        alignSelf: "flex-start",
                    }}
                >
                    <PackItem
                        packname="+ Create a new pack"
                        public={false}
                        selected={false}
                        onPress={async () => {
                            getUserData()
                                .then(user => {
                                    return addDoc(collection(firestore, "packs"), {
                                        pack_name: getAuth(firebase).currentUser.displayName + "s private pack!",
                                        pack_owner: user.ref,
                                        pack_size_black: 0,
                                        pack_size_white: 0,
                                        public: false
                                    })
                                })
                                .then(pack => {
                                    addDoc(collection(pack, "cards"), {
                                        contents: "This is your first white card! Click/tap here to start editing it!",
                                        pack,
                                        pick: 0,
                                        type: "white"
                                    })
                                    addDoc(collection(pack, "cards"), {
                                        contents: "This is your first black card! What did Grandpa say when he first played Unholy & Twisted?",
                                        pack,
                                        pick: 1,
                                        type: "black"
                                    })
                                    return addDoc(collection(pack, "cards"), {
                                        contents: "This card has some blank spots in it. Represented by _. Give _ a go!",
                                        pack,
                                        pick: 2,
                                        type: "black"
                                    })
                                })
                                .then(() => {
                                    listPacks()
                                })
                        }}
                    />
                    <FlatList
                        style={{height: "100%", alignSelf: "flex-start", width: "100%"}}
                        data={packs}
                        renderItem={({item}) => {
                            let data = item.data()
                            return <PackItem
                                packname={data.pack_name}
                                public={data.public}
                                selected={selectedPack === item.id}
                                onPress={() => {
                                    console.log("Pack clicked:", item.id)
                                    setSelectedPack(item.id)
                                }}
                            />
                        }}
                        extraData={packs}
                    />
                </View>
                <View
                    style={{
                        flexDirection: "row",
                        overflow: "scroll",
                        height: "100%",
                        alignSelf: "flex-start",
                        flexGrow: 1,
                        flexWrap: "wrap",
                        width: 10,
                        padding: 20
                    }}
                >
                    <View
                        style={{
                            flexDirection: "row",
                            width: "100%",
                            alignSelf: "flex-start",
                            flexGrow: 0,
                            flexWrap: "wrap",
                            padding: 20
                        }}
                    >
                        {cards.data.map((item) => {
                            let data = item.data()
                            return <EditableStrip
                                type={data.type}
                                contents={data.contents}
                                id={item.id}
                                shouldFocus={focusCard ? focusCard == item.id ? "focus" : "blur" : undefined}
                                onFocus={() => setFocusCard(null)}
                                onChange={(text) => {
                                    const newCard: PackCardDocument<any> = {
                                        ...data,
                                        contents: text,
                                        pick: data.type === CardTypes.WHITE ? 0 : text.match(/_/g).length || 1
                                    }
                                    setDoc(item.ref, newCard)
                                }}
                                onDelete={() => {
                                    deleteDoc(item.ref)
                                }}
                            />
                        })}
                    </View>
                </View>
            </View>
            <View
                style={{
                    backgroundColor: "#fff",
                    flexGrow: 0,
                    padding: 20,
                    flexDirection: "row",
                    alignItems: "center"
                }}
            >
                <View style={{flexGrow: 1, marginLeft: 10}}>
                    <Text>To create a blank section in your black card, use the underscore character (_) in the text field</Text>
                    <Text>Want to collaborate on a pack? Share this link; <a href={"https://unholyandtwisted.com/account/packs/" + selectedPack}>{"https://unholyandtwisted.com/account/packs/" + selectedPack + "/invite"}</a> </Text>
                </View>
                <TouchableOpacity
                    style={[MainStyles.secondaryButton,
                        {
                            marginLeft: 0,
                            marginRight: 10,
                            // backgroundColor: "#000"
                        }]}
                    onPress={async () => {
                        const cardData: PackCardDocument<CardTypes.WHITE> = {
                            contents: "New card",
                            pack: doc(firestore, "packs", selectedPack)
                                .withConverter(convertor<PackDocument>()),
                            pick: 0,
                            type: CardTypes.WHITE
                        }
                        const newCard = await addDoc(
                            collection(firestore, "packs", selectedPack, "cards"),
                            cardData
                        )

                        setFocusCard(newCard.id)
                    }}
                >
                    <Text style={{color: "#000"}}>+ Add white card</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={[MainStyles.primaryButton,
                        {
                            marginLeft: 0,
                            backgroundColor: "#000"
                        }]}
                    onPress={async () => {
                        const cardData: PackCardDocument<CardTypes.BLACK> = {
                            contents: "New card",
                            pack: doc(firestore, "packs", selectedPack)
                                .withConverter(convertor<PackDocument>()),
                            pick: 1,
                            type: CardTypes.BLACK
                        }
                        await addDoc(
                            collection(firestore, "packs", selectedPack, "cards"),
                            cardData
                        )

                        // Refresh the navigator
                        refreshFirstoreNavigator()
                    }}
                >
                    <Text style={MainStyles.primaryButtonText}>+ Add black card</Text>
                </TouchableOpacity>
            </View>
        </NavWrapper>
    );
}

const styles = StyleSheet.create({
    pack: {
        borderRadius: 5,
        padding: 20,
        backgroundColor: "#fff",
        marginBottom: 10,
        width: "100%"
    },

    packname: {
        color: "#000"
    },

    textInput: {
        height: 100
    }
})

function PackItem(props: PackItemInterface) {
    return (<TouchableOpacity
        style={[styles.pack, {backgroundColor: props.selected ? "#023859" : "#fff"}]}
        onPress={props.onPress}
    >
        <Text style={[styles.packname, {color: props.selected ? "#fff" : "#000"}]}>{props.packname}
            {props.public ? <Text style={[styles.packname, {color: "grey"}]}> - Public</Text> : undefined}
        </Text>

    </TouchableOpacity>)
}

interface PackItemInterface {
    packname: string,
    public: boolean,
    selected: boolean,

    onPress(): void
}
