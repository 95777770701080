import {StyleSheet, TextInput, View} from "react-native";
import {colours, styles as MainStyles} from "./MainStyles"
import React, {useEffect, useRef, useState} from "react";
import {CardTypes} from "../config/types";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

export function EditableStrip(props: CardInterface) {
    const [value, setValue] = useState(props.contents)
    const [isFocused, setIsFocused] = useState(false)
    const input = useRef<TextInput>(null)

    useEffect(() => {
        setValue(props.contents)
    }, [props.contents]);

    useEffect(() => {
        if (!props.shouldFocus) return
        else if (props.shouldFocus === "focus") {
            console.log("focusing...")
            input.current.focus()
        }
        else if (props.shouldFocus === "blur" && input.current.isFocused()) input.current.blur()
    }, [props.shouldFocus]);

    return <div>
        <View style={[MainStyles.card, {
            padding: 0,
            height: null,
            width: null,
            backgroundColor: props.type === CardTypes.WHITE ? "white" : "black",
        }]}>
            <div style={{height: 0, overflow: 'hidden', opacity: 0, paddingRight: 20}}>{value}</div>
            <div
                className="editable-strip"
                style={{
                    color: props.type === CardTypes.WHITE ? "black" : "white",
                    padding: 10
                }}
            >
                <div className="delete-button" onClick={() => props.onDelete()}>
                    <FontAwesomeIcon icon={faTrash} color={props.type === CardTypes.WHITE ? "black" : "white"}/>
                </div>
                <TextInput
                    value={value}
                    onChangeText={(v) => setValue(v)}
                    ref={input}
                    id={"edit_" + props.id}
                    onFocus={() => {
                        setIsFocused(true)
                        props.onFocus()
                    }}
                    onBlur={() => {
                        setIsFocused(false)
                        props.onChange(value)
                    }}
                    style={[
                        {width: "100%", color: props.type === CardTypes.WHITE ? "#000" : "#fff"}
                    ]}
                />
            </div>
        </View>
    </div>
}

interface CardInterface {
    id: string,
    type: CardTypes,
    contents: string,
    shouldFocus?: "blur" | "focus"
    onFocus(): void
    onDelete(): void,
    onChange(text: string): void
}

const extraStyles = StyleSheet.create({
    wildcard: {opacity: .5, color: "#fff"}
})