import {Dimensions, StyleSheet} from "react-native";

export const colours = {
    2: "#AB0563",
    1: "#059CAB",
    3: "#bf06a0",
    4: "#0d0d0d",
    5: "#730255"
}
export const styles = StyleSheet.create({
    body: {
        // fontFamily: "Lato_400Regular",
        height: Dimensions.get("window").height,
    },

    container: {
        flex: 1,
        backgroundColor: colours[2],
        alignItems: 'center',
        justifyContent: 'center',
        height: 500,
        padding: 40,
    },

    navBar: {
        fontFamily: "RubikIso_400Regular",
        padding: 20,
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: colours[2]
    },

    navBarHeader: {
        margin: 0,
        color: "#fff",
        flexGrow: 1,
        fontFamily: "RubikIso_400Regular",
        fontSize: 32
    },

    primaryButton: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: colours[1],
        borderRadius: 5,
        marginLeft: 10,
    },

    primaryButtonDisabled: {
        backgroundColor: "#aaa",
    },

    primaryButtonText: {
        color: "#fff",
        fontWeight: "bold"
    },

    secondaryButton: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 5,
    },

    secondaryButtonText: {
        color: "#fff",
        fontWeight: "bold"
    },

    secondaryButtonInverted: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 5,
        backgroundColor: "#fff"
    },

    secondaryButtonInvertedText: {
        color: colours[1],
        fontWeight: "bold"
    },

    textInput: {
        marginTop: 10,
        padding: 10,
        borderRadius: 3,
        borderWidth: 1,
        fontFamily: "Lato_400Regular",
        minWidth: 300
    },

    card: {
        marginBottom: 10,
        width: "2.5in",
        height: "3.5in",
        borderRadius: 5,
        padding: 20,
        marginRight: 5,
        marginLeft: 5,
        flexGrow: 0,
        flexShrink: 0,
        borderWidth: 1,
        borderColor: "#eee"
    }
});