import {Modal, View, Text, Animated, Easing, TextInput, TouchableOpacity, Dimensions} from "react-native";
import React, {useState} from "react";
import {faArrowRight, faSpinner} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {useEffect, useRef} from "react";
import {enforceSignIn, setDisplayName, useAuth} from "../config/firebase";
import {styles as MainStyles} from "./MainStyles";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {RootStackParamList} from "../../App";
import {RouteProp} from "@react-navigation/native";
import SetUsername from "./SetUsername";

export default function LoadingModal(props: LoadingModalProps) {
    const animation = new Animated.Value(0)
    const user = useAuth()
    const spin = animation.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '360deg']
    })
    const [showDisplayNameSet, setShowDisplayNameSet] = useState(false)
    const [displayName, _setDisplayName] = useState("")
    let displayNameTimeout = setTimeout(() => {
        // console.log("USER:", user)
        if (!user?.displayName) setShowDisplayNameSet(true)
    }, 5000)

    useEffect(() => {
        let loop = Animated.loop(
            Animated.timing(animation, {
                toValue: 1,
                duration: 3000,
                easing: Easing.linear,
                useNativeDriver: false
            })
        )
        loop.start()
        return () => {
            loop.stop()
        }
    })

    useEffect(() => {
        if (user) clearTimeout(displayNameTimeout)
        return () => {}
    }, [user])
    useEffect(() => {
        return () => clearTimeout(displayNameTimeout)
    }, [])

    if (!props.show) return
    return <Modal>
        {showDisplayNameSet ? <View
            style={{
                backgroundColor: "#023859",
                justifyContent: "center",
                alignItems: "center",
                flexGrow: 1
            }}
        >
            <View>
                <Text
                    style={{
                        fontWeight: "bold",
                        color: "#fff"
                    }}
                >Please enter your name/nickname below</Text>
                <View
                    style={{
                        flexDirection: "row",
                        marginTop: 10,
                        alignItems: "center",
                        flexWrap: "wrap",
                        flexGrow: 1,
                        width: "100%",
                        backgroundColor: "#fff",
                        padding: 0,
                        borderRadius: 10,
                        overflow: "hidden"
                    }}
                >
                    <TextInput
                        style={{
                            padding: 9,
                            borderTopLeftRadius: 5,
                            borderBottomLeftRadius: 5,
                            backgroundColor: "#fff",
                            width: 200,
                            flexGrow: 1,
                            textTransform: "uppercase"
                        }}
                        autoCapitalize={"characters"}
                        maxLength={6}
                        value={displayName}
                        placeholder="Enter a nickname..."
                        onChangeText={(v) => {
                            _setDisplayName(v.replaceAll(" ", "").toUpperCase())
                        }}
                    />
                    <TouchableOpacity
                        style={[MainStyles.primaryButton,
                            (!displayName) ? MainStyles.primaryButtonDisabled : undefined,
                            {
                                marginLeft: 0,
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                height: "100%"
                            }
                        ]}
                        onPress={async () => {
                            await setDisplayName(displayName)
                            // @ts-ignore
                            setShowDisplayNameSet(false)
                            props.onUsernameChange()
                        }}
                    >
                        <FontAwesomeIcon icon={faArrowRight} style={MainStyles.primaryButtonText}/>
                    </TouchableOpacity>
                </View>
            </View>
        </View> : <View
            style={{
                backgroundColor: "#023859",
                justifyContent: "center",
                alignItems: "center",
                flexGrow: 1
            }}
        >
            <Animated.View
                style={{transform: [{rotate: spin}], marginBottom: 20}}
            ><FontAwesomeIcon icon={faSpinner} style={{color: "#fff"}} size={64}/></Animated.View>
            <Text style={{color: "#fff"}}>{
                user && user.displayName ? "Loading the game for " + user.displayName : "Preparing the jokes..."
            }</Text>
        </View>}
    </Modal>
}

interface LoadingModalProps {
    show: boolean,
    children?: React.ReactNode,
    onUsernameChange: () => void
}