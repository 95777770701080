import {faSquare, faSquareCheck} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import React from "react";
import {styles as MainStyles} from "./MainStyles";
import {Text, TouchableOpacity, View} from "react-native";

export default function CheckboxButton(props: CheckboxButtonInterface) {
    return <TouchableOpacity
        style={[MainStyles.secondaryButton,
            {
                marginLeft: 0,
                flexDirection: "row",
                alignContent: "center",
                justifyContent: "center"
            }]}
        onPress={props.onToggle}
    >
        <FontAwesomeIcon icon={props.selected ? faSquareCheck : faSquare} style={{color: props.color}}/>
        <View>{props.children}</View>
    </TouchableOpacity>
}

interface CheckboxButtonInterface {
    children?: React.ReactNode
    onToggle(): void
    selected: boolean
    color: string
}