import { io } from 'socket.io-client';
import {getAuth} from "firebase/auth";
import Constants from "expo-constants";
import {auth} from "./firebase";

const socket = io(Constants.expoConfig.extra.wsUrl)
let gameid: string = ""

socket.on("authentication_request", async (callback) => {
    const token = await auth.currentUser?.getIdToken()
    socket.emit("authenticate", token, gameid, (result: boolean) => {
        if (result) {
            // Authenticated succesfully!
        } else {
            console.error("Server denied authentication attempt")
        }
    })
})

export default socket
export function setGameID(id: string) {gameid = id}