import {FlatList, ScrollView, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {StatusBar} from "expo-status-bar";
import NavWrapper from "../components/NavWrapper";
import {colours, styles as MainStyles} from "../components/MainStyles";
import firebase, {enforceSignIn, getUserData, listPacks} from "../config/firebase";
import {useEffect, useRef, useState} from "react";
import WhiteCard from "../components/WhiteCard";
import BlackCard from "../components/BlackCard";
import {addDoc, collection, getDocs, getFirestore, QueryDocumentSnapshot} from "firebase/firestore";
import {faFaceGrinBeamSweat} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import CheckboxButton from "../components/CheckboxButton";
import {CardTypes, convertor, GameDocument, PackCardDocument, WildcardTypes} from "../config/types";
import {Colors} from "react-native/Libraries/NewAppScreen";

const firestore = getFirestore(firebase)

export default function StartGame(props: any) {
    const {packs} = listPacks()
    const [cards, setCards] = useState<QueryDocumentSnapshot<PackCardDocument<CardTypes>>[]>([])
    const [selectedPacks, setSelectedPacks] = useState<string[]>([])
    const [numCardColumns, setNumCardColumns] = useState(2)
    const cardList = useRef(null)
    const [containerWidth, setContainerWidth] = useState(null);
    const [wildcards, setWildcards] = useState<WildcardTypes[]>([])

    const handleLayout = () => {
        const width = cardList.current.offsetWidth
        console.log("Updating container width...")
        setContainerWidth(width)
    }

    const toggleWildcard = (wildcard: WildcardTypes) => {
        let _wildcards: number[] = JSON.parse(JSON.stringify(wildcards))
        if (_wildcards.includes(wildcard)) {
            console.log(_wildcards.indexOf(wildcard))
            _wildcards.splice(_wildcards.indexOf(wildcard), 1)
            setWildcards(_wildcards)
        }
        else {
            _wildcards.push(wildcard)
            setWildcards(_wildcards)
        }
    }

    useEffect(() => {
        enforceSignIn()
    }, [])

    useEffect(() => {
        if (containerWidth) {
            const newNumColumns = Math.floor(containerWidth / 250)
            setNumCardColumns(newNumColumns < 1 ? 1 : newNumColumns)
        }
    }, [containerWidth])

    useEffect(() => {
        if (selectedPacks.length === 0 || selectedPacks.length >= 10) {
            setCards([])
            return
        }

        const process_data = async () => {
            let cards: QueryDocumentSnapshot<PackCardDocument<CardTypes>>[] = []
            for (let pack of packs.filter(pack => selectedPacks.includes(pack.id))) {
                let data = await getDocs(
                    collection(pack.ref, "cards").withConverter(convertor<PackCardDocument<CardTypes>>())
                )
                data.forEach(card => cards.push(card))
            }
            cards.sort((a, b) => {
                let a_data = a.data()
                let b_data = b.data() || null
                if (!a_data || !b_data) return 0
                return a_data.type < b_data.type ? 1 : -1
            })
            setCards(cards)
        }

        process_data()
    }, [selectedPacks])

    return (
        <NavWrapper navigation={props.navigation} style={{flexGrow: 1}}>
            <ScrollView
                style={{
                    flexShrink: 1,
                    flexGrow: 1,
                    // alignItems: "flex-start",
                    // overflow: "hidden",
                    height: 10
                }}
            >
                <View style={{
                    padding: 20,
                    borderRadius: 10,
                    margin: 10,
                    backgroundColor: colours["2"]
                }}>
                    <h1 style={{margin: 0, textAlign: "center", color: "#fff"}}>Create a new lobby</h1>
                </View>
                <div className="lobby-settings-wrapper">
                    <View
                        style={{
                            padding: 20,
                            backgroundColor: "#eee",
                            overflow: "scroll",
                            flexGrow: 1,
                            flexShrink: 1,
                            alignSelf: "flex-start",
                            width: "100%"
                        }}
                    >
                        <View style={{flexDirection: "row", alignItems: "center"}}>
                            <h1>Select packs:</h1>
                        </View>
                        <View style={{flexDirection: "row", alignItems: "center"}}>
                            <TouchableOpacity
                                style={[MainStyles.secondaryButton,
                                    {
                                        marginLeft: 0
                                    }]}
                                onPress={async () => {
                                    if (selectedPacks.length === 0) {
                                        setSelectedPacks(packs.map(i => i.id))
                                        // let cards = []
                                        for (let pack of packs) {
                                            // Update cards
                                            // cards = cards.concat(await getPackCards(pack))
                                            // cards.sort((a,b) => {
                                            //     let a_data = a.data()
                                            //     let b_data = b.data() || null
                                            //     if (!a_data || !b_data) return 0
                                            //     return a_data.type < b_data.type ? 1 : -1
                                            // })
                                            // setCards(cards)
                                        }
                                    }
                                    else {
                                        setSelectedPacks([])
                                        setCards([])
                                    }
                                }}
                            >
                                <Text
                                    style={[MainStyles.secondaryButtonText, {color: "#000"}]}>{selectedPacks.length === 0 ? "Add all packs" : "Deselect all packs"}</Text>
                            </TouchableOpacity>
                            <View style={{flexGrow: 1, marginLeft: 10}}>
                                <Text>Packs selected: {selectedPacks.length}, Cards selected: {cards.length}</Text>
                            </View>
                        </View>
                        {packs.length === 0 ? "Loading packs..." : undefined}
                        <View style={{
                            flexDirection: "row",
                            flexWrap: "wrap",
                        }}>
                            {packs.map(item => {
                                const data = item.data()
                                return <PackItem
                                    packname={data.pack_name}
                                    public={data.public}
                                    selected={selectedPacks.includes(item.id)}
                                    onPress={async () => {
                                        let _packs = JSON.parse(JSON.stringify(selectedPacks))
                                        if (_packs.includes(item.id)) {
                                            _packs.splice(_packs.indexOf(item.id), 1)

                                            // // Update cards
                                            // for (let i = 0; i < cards.length; i++) {
                                            //     console.log(cards[i].data().pack.id, item.id)
                                            //     if (cards[i].data().pack.id === item.id) {
                                            //         cards.splice(i, 1)
                                            //     }
                                            // }
                                            // setCards(cards)
                                        }
                                        else {
                                            _packs.push(item.id)

                                            // Update cards
                                            // let _cards = cards
                                            // _cards = _cards.concat(await getPackCards(item))
                                            // console.log(item.data())
                                            // console.log(_cards)
                                            // _cards.sort((a,b) => {
                                            //     let a_data = a.data()
                                            //     let b_data = b.data() || null
                                            //     if (!a_data || !b_data) return 0
                                            //     return a_data.type < b_data.type ? 1 : -1
                                            // })
                                            // setCards(_cards)
                                        }
                                        setSelectedPacks(_packs)
                                    }}
                                />
                            })}
                        </View>
                    </View>
                    <View>
                        <View
                            style={{width: "100%", flexWrap: "wrap"}}
                        >
                            <h2>Wildcards</h2>
                            <View
                                style={{
                                    flexWrap: "wrap", width: "100%", alignItems: "flex-start"
                                }}
                            >
                                <CheckboxButton onToggle={() => {
                                    toggleWildcard(WildcardTypes.BLANK)
                                }} selected={wildcards.includes(WildcardTypes.BLANK)} color="#000">
                                    <View style={{marginLeft: 10}}>
                                        <Text
                                            style={{fontWeight: "bold"}}
                                        >Blank cards</Text>
                                        <Text>Write your own custom text on a card</Text>
                                    </View>
                                </CheckboxButton>

                                <CheckboxButton onToggle={() => {
                                    toggleWildcard(WildcardTypes.PICKUP)
                                }} selected={wildcards.includes(WildcardTypes.PICKUP)} color="#000">
                                    <View style={{marginLeft: 10}}>
                                        <Text
                                            style={{fontWeight: "bold"}}
                                        >Pick up 5</Text>
                                        <Text>Using this card will add 5 extra cards to your hand</Text>
                                    </View>
                                </CheckboxButton>

                                <CheckboxButton onToggle={() => {
                                    toggleWildcard(WildcardTypes.SHUFFLE)
                                }} selected={wildcards.includes(WildcardTypes.SHUFFLE)} color="#000">
                                    <View style={{marginLeft: 10}}>
                                        <Text
                                            style={{fontWeight: "bold"}}
                                        >Shuffle the deck</Text>
                                        <Text>Shuffle all cards back in to the deck</Text>
                                    </View>
                                </CheckboxButton>

                                <CheckboxButton onToggle={() => {
                                    toggleWildcard(WildcardTypes.SKIP)
                                }} selected={wildcards.includes(WildcardTypes.SKIP)} color="#000">
                                    <View style={{marginLeft: 10}}>
                                        <Text
                                            style={{fontWeight: "bold"}}
                                        >Skip</Text>
                                        <Text>Skip to voting immediately</Text>
                                    </View>
                                </CheckboxButton>
                            </View>
                        </View>
                    </View>
                </div>
            </ScrollView>
            <View
                style={{
                    backgroundColor: "#fff",
                    flexGrow: 0,
                    padding: 20,
                    flexDirection: "row",
                    alignItems: "center"
                }}
            >
                <TouchableOpacity
                    style={[MainStyles.primaryButton,
                        {
                            marginLeft: 0
                        }]}
                    onPress={() => {
                        getUserData()
                            .then(user => {
                                let data: GameDocument = {
                                    deck: [],
                                    gamehost: user.ref,
                                    packs: packs.filter(pack => selectedPacks.includes(pack.id))
                                        .map(pack => {
                                            return {
                                                ref: pack.ref,
                                                pack_size_white: pack.data().pack_size_white,
                                                pack_size_black: pack.data().pack_size_black
                                            }
                                        }),
                                    round: null,
                                    active: true,
                                    card_types: wildcards
                                }
                                return addDoc(
                                    collection(firestore, "games").withConverter(convertor<GameDocument>()), data)
                            })
                            .then(game => {
                                props.navigation.navigate("Game", {id: game.id})
                            })
                    }}
                >
                    <Text style={MainStyles.primaryButtonText}>Create game lobby</Text>
                </TouchableOpacity>
            </View>
        </NavWrapper>
    );
}

const styles = StyleSheet.create({
    pack: {
        borderRadius: 5,
        padding: 10,
        backgroundColor: "#fff",
        marginBottom: 10,
        marginRight: 10
    },

    packname: {
        color: "#000"
    }
})

function PackItem(props: PackItemInterface) {
    return (<TouchableOpacity
        style={[styles.pack, {backgroundColor: props.selected ? "#023859" : "#fff"}]}
        onPress={props.onPress}
    >
        <Text style={[styles.packname, {color: props.selected ? "#fff" : "#000"}]}>{props.packname}
            {props.public ? <Text style={[styles.packname, {color: "grey"}]}> - Public</Text> : undefined}
        </Text>

    </TouchableOpacity>)
}

interface PackItemInterface {
    packname: string,
    public: boolean,
    selected: boolean,

    onPress(): void
}
