import {Dimensions, StyleProp, Text, TouchableOpacity, View, ViewStyle, Animated, Easing, FlatList} from "react-native";
import {StatusBar} from "expo-status-bar";
import {styles as MainStyles} from "./MainStyles";
import WhiteCard from "./WhiteCard";
import {useEffect, useRef, useState} from "react";
import BlackCard from "./BlackCard";

export default function CardsBackgroundAnimation(props: MainWrapperProps) {
    return (
        <Animated.View
            style={[
                {
                    flexDirection: "row",
                    overflow: "hidden",
                    position: "absolute",
                    alignContent: "center",
                    justifyContent: "center",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                },
                props.style
            ]}
        >
            {[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}].map((item, index) => {
                return <AnimatedRow reversed={index % 2 === 1} cardType={props.cardType}/>
            })}
        </Animated.View>
    );
}

interface MainWrapperProps {
    style?: StyleProp<ViewStyle>
    cardType: "white" | "black"
}

function AnimatedRow(props) {
    const Animation = useRef(new Animated.Value(-346)).current

    useEffect(() => {
        let animation
        if (props.reversed) {
            animation = Animated.timing(Animation,
                {
                    toValue: -692,
                    duration: 5000,
                    easing: Easing.linear,
                    useNativeDriver: false
                })
        } else {
            animation =
                Animated.timing(Animation,
                    {
                        toValue: 0,
                        duration: 5000,
                        easing: Easing.linear,
                        useNativeDriver: false
                    })
        }
        let loop = Animated.loop(animation)
        // loop.start()

    }, [])

    return <div className={props.reversed ? "card_background_row_reverse" : "card_background_row"}>
        {props.reversed ?
            [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}].reverse().map((item, index) => {
                return props.cardType == "white" ? <WhiteCard text=""/> : <BlackCard text=""/>
            }) :
            [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}].map((item, index) => {
                return props.cardType == "white" ? <WhiteCard text=""/> : <BlackCard text=""/>
            })}
    </div>
}

function getRandomFromArray(array) {
    return array[Math.floor(Math.random() * array.length)]
}

interface AnimatedRowProps {
    reverse: boolean,
    cardType: "white" | "black"
}