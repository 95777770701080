import {
    Touchable,
    TouchableOpacity,
    TouchableOpacityBase,
    Text,
    Animated,
    View,
    ViewProps,
    StyleProp, ViewStyle
} from "react-native";
import React, {useRef, useState} from "react";

export default function AsyncButton(props: AsyncButtonProps) {
    const [animating, setAnimating] = useState(false)
    const animation_value = useRef(new Animated.Value(0)).current
    const animation = useRef(Animated.loop(
        Animated.timing(
            animation_value, {
                toValue: 1,
                duration: 500,
                useNativeDriver: false
            }
        )
    )).current

    return <View
        style={props.parentStyle}
    >
        <TouchableOpacity
            onPress={async () => {
                console.log("Animation start")
                setAnimating(true)
                animation.start()
                await props.asyncOnPress()
                animation.stop()
                animation.reset()
                animation_value.setValue(0)
                setAnimating(false)
                console.log("Animation end")
            }}
            disabled={animating || typeof props.disabled === "undefined" ? false : props.disabled}
            style={props.style}
        >
            {props.children}
        </TouchableOpacity>
        <Animated.View
            style={{
                width: animation_value.interpolate({
                    inputRange: [0,1],
                    outputRange: ["0%", "100%"]
                }),
                opacity: animation_value.interpolate({
                    inputRange: [0,1],
                    outputRange: [1,0]
                }),
                height: 39,
                backgroundColor: "#fff",
                position: "absolute",
                top: 0,
                left: 0
            }}
        />
    </View>
}

interface AsyncButtonProps {
    asyncOnPress(): Promise<void>,
    children?: React.ReactNode,
    style?: StyleProp<ViewStyle>,
    parentStyle?: StyleProp<ViewStyle>,
    disabled?: boolean
}