import {Dimensions, ImageBackground, Modal, StyleProp, Text, TouchableOpacity, View, ViewStyle} from "react-native";
import {StatusBar} from "expo-status-bar";
import {colours, styles as MainStyles} from "./MainStyles";
import firebase, {uploadUserPFP, useAuth} from "../config/firebase";
// import {} from "@fortawesome/free-solid-svg-icons"
// import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {getAuth} from "firebase/auth";
import "../css/deck.css"
import {useMediaQuery} from "react-responsive";
import {useState} from "react";
import * as DocumentPicker from 'expo-document-picker';

export default function NavWrapper(props: MainWrapperProps) {
    const user = useAuth()
    const [showUserMenu, setShowUserMenu] = useState(false)

    const isMobile = useMediaQuery({
        maxDeviceWidth: 1224
    });

    if (user && !user.isAnonymous) {
        return (
            <View style={MainStyles.body}>
                <View style={MainStyles.navBar}>
                    {Dimensions.get("window").width >= 700 ?
                        (<div style={{flexGrow: 1}}><img src={require("../../assets/Color logo - no background.svg")} style={{height: 50}}/></div>) :
                        (<h1 style={MainStyles.navBarHeader} onClick={() => {props.navigation.navigate("Home")}}>UAH</h1>)
                    }
                    <TouchableOpacity
                        style={{
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 10
                        }}
                        onPress={() => {
                            setShowUserMenu(true)
                        }}
                    >
                        <ImageBackground
                            source={{uri: user.photoURL}}
                            style={{
                                width: 40,
                                height: 40,
                                overflow: "hidden",
                                backgroundColor: colours[4],
                                borderRadius: 20
                            }}
                        />
                    </TouchableOpacity>
                    {isMobile ? undefined : [
                        <TouchableOpacity style={MainStyles.primaryButton}
                                          onPress={() => {
                                              setShowUserMenu(false)
                                              props.navigation.navigate("Start a new game")
                                          }}
                        >
                            <Text style={MainStyles.primaryButtonText}>Start a game!</Text>
                        </TouchableOpacity>,
                        <TouchableOpacity style={MainStyles.secondaryButton}
                                          onPress={async () => {
                                              props.navigation.navigate("Pack Manager")
                                          }}
                        >
                            <Text style={MainStyles.secondaryButtonText}>My Packs</Text>
                        </TouchableOpacity>,
                        <TouchableOpacity style={MainStyles.secondaryButton}
                                          onPress={async () => {
                                              await getAuth(firebase).signOut()
                                              setShowUserMenu(false)
                                              props.navigation.navigate("Login")
                                          }}
                        >
                            <Text style={MainStyles.secondaryButtonText}>Logout</Text>
                        </TouchableOpacity>
                    ]}
                </View>
                <Modal
                    visible={showUserMenu}
                    transparent={true}
                >
                    <TouchableOpacity
                        style={{
                            backgroundColor: "rgba(0,0,0,.75)",
                            flexGrow: 1,
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                        onPress={() => setShowUserMenu(false)}
                        activeOpacity={1}
                    >
                        <View
                            style={{
                                backgroundColor: "#fff",
                                width: 300,
                                padding: 10
                            }}
                        >
                            <TouchableOpacity style={[MainStyles.primaryButton, {
                                marginLeft: 0
                            }]}
                                              onPress={() => {
                                                  props.navigation.navigate("Start a new game")
                                              }}
                            >
                                <Text style={MainStyles.primaryButtonText}>Start a game!</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={[MainStyles.secondaryButton, {
                                marginLeft: 0
                            }]}
                                              onPress={async () => {
                                                  const picture = await DocumentPicker.getDocumentAsync({
                                                      type: "image/*"
                                                  })
                                                  uploadUserPFP(picture.file)
                                                      .then(() => {
                                                          alert("Your new profile picture has been uploaded! It may take up to 24hrs to update on all devices")
                                                      })
                                                      .catch((e) => {
                                                          if (e.reason) {
                                                              alert(`Your new profile picture couldn't be uploaded for this reason: ${e.reason}`)
                                                          } else {
                                                              alert("An unknown error occurred while uploading your profile picture. Please try again.")
                                                          }
                                                      })
                                              }}
                            >
                                <Text style={[MainStyles.secondaryButtonText, {
                                    color: "#000"
                                }]}>Change profile picture</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={[MainStyles.secondaryButton, {
                                marginLeft: 0
                            }]}
                                              onPress={async () => {
                                                  await getAuth(firebase).signOut()
                                                  props.navigation.navigate("Login")
                                              }}
                            >
                                <Text style={[MainStyles.secondaryButtonText, {
                                    color: "#000"
                                }]}>Logout</Text>
                            </TouchableOpacity>
                        </View>
                    </TouchableOpacity>
                </Modal>
                <View style={props.style}>
                    {props.children}
                </View>
            </View>
        );
    } else {
        return (
            <View style={MainStyles.body}>
                <View style={MainStyles.navBar}>
                    {Dimensions.get("window").width >= 700 ?
                        (<div style={{flexGrow: 1}}><img src={require("../../assets/Color logo - no background.svg")} style={{height: 50}}/></div>) :
                        (<h1 style={MainStyles.navBarHeader} onClick={() => {props.navigation.navigate("Home")}}>UAH</h1>)
                    }
                    <div className="secondaryButton">
                        <TouchableOpacity style={MainStyles.secondaryButton}
                                          onPress={() => props.navigation.navigate("Login")}
                        >
                            <Text style={MainStyles.secondaryButtonText}>Log In</Text>
                        </TouchableOpacity>
                    </div>
                    <TouchableOpacity style={MainStyles.primaryButton}>
                        <Text style={MainStyles.primaryButtonText}
                              onPress={() => props.navigation.navigate("Sign Up")}
                        >Sign Up</Text>
                    </TouchableOpacity>
                    {user ? <TouchableOpacity style={MainStyles.primaryButton}>
                        <Text style={MainStyles.primaryButtonText}
                              onPress={() => user.delete()}
                        >Reset data</Text>
                    </TouchableOpacity>
                    : undefined}
                </View>
                <View style={props.style}>
                    {props.children}
                </View>
            </View>
        );
    }
}

interface MainWrapperProps {
    children?: React.ReactNode,
    navigation: any,
    style?: StyleProp<ViewStyle>
}