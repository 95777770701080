import {Animated, Dimensions, Easing, ImageBackground, Text, View} from "react-native";
import {colours} from "./MainStyles";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {faUser, faWifi} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useRef, useState} from "react";
import {DocumentSnapshot, QueryDocumentSnapshot} from "firebase/firestore";
import {CardTypes, GamePlayerDocument, PackCardDocument} from "../config/types";

export default function WinnerDisplay(props: WinnerDisplayProps) {
    const [profileURI, setProfileURI] = useState(props.player?.data().profile_picture || "")
    const width = useRef(new Animated.Value(156)).current

    let to_width = Dimensions.get("window").width
    if (to_width * 0.8 >= 500) to_width = 500

    useEffect(() => {
        console.log("TRAVELLING TO WIDTH:", to_width)
        if (props.player) {
            Animated.sequence([
                Animated.delay(1000),
                Animated.timing(width, {
                    toValue: to_width,
                    duration: 500,
                    useNativeDriver: false,
                    easing: Easing.inOut(Easing.ease)
                })
            ]).start()
        } else {
            Animated.timing(width, {
                toValue: 156,
                duration: 500,
                useNativeDriver: false,
                easing: Easing.inOut(Easing.ease)
            }).start()
        }
    }, [props.player])

    return <div
        className="winner_wrapper"
        style={{
            opacity: props.player ? 1 : 0,
            pointerEvents: props.player ? "auto" : "none"
        }}
    >
        <Animated.View
            style={{
                backgroundColor: "#fff",
                padding: 20,
                borderRadius: 10,
                alignItems: "center",
                // justifyContent: "center",
                flexGrow: 0,
                flexShrink: 0,
                flexDirection: "row",
                width: width,
                maxWidth: Dimensions.get("window").width,
                overflow: "hidden"
            }}
        >
            <View
                style={{
                    width: to_width - 40,
                    gap: 20,
                    alignItems: "center",
                    // justifyContent: "center",
                    flexGrow: 0,
                    flexShrink: 0,
                    flexDirection: "row",
                    maxWidth: Dimensions.get("window").width,
                    overflow: "hidden"
                }}
            >
                <View
                    style={{
                        width: 116,
                        // height: 50,
                        // alignContent: "center",
                        // justifyContent: "center",
                        // marginRight: 10,
                        alignSelf: "center",
                        flexShrink: 0
                    }}
                >
                    {/*<View style={{*/}
                    {/*    position: "absolute",*/}
                    {/*    transform: [{rotate: "-45deg"}],*/}
                    {/*    zIndex: 2*/}
                    {/*}}>*/}
                    {/*    <FontAwesomeIcon icon={faCrown} color={colours[1]}/>*/}
                    {/*</View>*/}
                    <View
                        style={{
                            borderRadius: 52,
                            width: 116,
                            borderWidth: 6,
                            padding: 2,
                            borderColor: props.player?.data().submitted ? colours[3] : "transparent"
                        }}
                    >
                        <ImageBackground
                            source={{uri: profileURI}}
                            style={{
                                // marginTop: 10,
                                borderRadius: 50,
                                width: 100,
                                height: 100,
                                backgroundColor: "#000",
                                justifyContent: "center",
                                overflow: "hidden",
                            }}
                            resizeMode="cover"
                            // animated={false}
                        >
                            {profileURI ? undefined : <View
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: colours[2]
                                }}
                            >
                                <FontAwesomeIcon icon={faUser} size={24} color={"#fff"}/>
                            </View>}
                        </ImageBackground>
                    </View>

                    <Text style={{
                        textAlign: "center",
                        backgroundColor: props.player?.data().submitted ? colours[3] : "#000",
                        color: "#fff",
                        fontWeight: "100",
                        marginTop: -10,
                        borderRadius: 5,
                        width: "100%",
                        fontSize: 24,
                        zIndex: 2
                    }}>{props.player?.data().username}</Text>
                </View>
                <View
                    style={{flexShrink: 1}}
                >{props.children}</View>
            </View>
        </Animated.View>
    </div>
}

interface WinnerDisplayProps {
    player?: DocumentSnapshot<GamePlayerDocument>,
    children?: React.ReactNode,
}
