import {
    onSnapshot,
    Query,
    DocumentReference,
    DocumentSnapshot,
    QueryDocumentSnapshot,
    getDoc,
    doc, getDocs
} from "firebase/firestore";
import React, {useEffect, useRef, useState} from "react";
import {convertor} from "../config/types";

export function useFirestoreDocument<D>(reference: DocumentReference) {
    const [data, setData] = useState<DocumentSnapshot<D>>()
    useEffect(() => {
        getDoc(reference)
            .then(doc => {
                setData(doc as DocumentSnapshot<D>);
            })
    }, []);
    return data
}

export function useFirestoreDocumentLive<D>(query: DocumentReference<D>){
    const [data, setData] = useState<DocumentSnapshot<D>>()

    useEffect(() => {
        const unsubscribe = onSnapshot(
            query.withConverter(convertor<D>()),
            (snapshot) => {
                setData(snapshot)
            }
        )
        return () => {
            unsubscribe()
        }
    }, []);

    return data
}

export function useFirestoreDocumentsLive<D>(query: Query<D>){
    const [data, setData] = useState<QueryDocumentSnapshot<D>[]>()

    useEffect(() => {
        const unsubscribe = onSnapshot(
            query.withConverter(convertor<D>()),
            (snapshot) => {
                setData(snapshot.docs)
            }
        )
        return () => {
            unsubscribe()
        }
    }, []);

    return data
}

export function useFirestoreNavigator<D>(
    sort: ((a: QueryDocumentSnapshot<D>, b: QueryDocumentSnapshot<D>) => number) = () => 0
) {
    const [data, setData] = useState<QueryDocumentSnapshot<D>[]>([])
    const goto = useRef((query: Query<any>) => {
        getDocs(query.withConverter(convertor<D>()))
            .then(results => {
                setData(results.docs.sort(sort))
            })
    }).current
    const clear = useRef(() => {
        setData([])
    }).current

    return {data, goto, clear}
}

export function useFirestoreNavigatorLive<D>(
    sort: ((a: QueryDocumentSnapshot<D>, b: QueryDocumentSnapshot<D>) => number) = () => 0
) {
    const [data, setData] = useState<QueryDocumentSnapshot<D>[]>([])
    const [subscriber, setSubscriber] = useState<() => void>()
    const [query, setQuery] = useState<Query<any>>()

    useEffect(() => {
        if (!query) return

        return onSnapshot(query, (snapshot) => {
            console.log("SNAPSHOT!")
            setData(snapshot.docs.sort(sort))
        })
    }, [query]);

    const clear = useRef(() => {
        setData([])
    }).current

    return {data, clear, setQuery}
}