import {initializeApp} from "firebase/app"
import "firebase/auth";
import React, {useEffect} from "react";
import {
    onAuthStateChanged,
    signInWithEmailAndPassword,
    getAuth,
    User,
    updateProfile,
    signInAnonymously,
} from "firebase/auth";
import {
    getFirestore,
    getDoc,
    getDocs,
    query,
    collectionGroup,
    where,
    collection, QueryDocumentSnapshot, DocumentReference
} from "firebase/firestore"
// import {collection} from "./types";
import {
    getStorage,
    ref,
    uploadBytes,
    getDownloadURL
} from "firebase/storage"
import Constants from "expo-constants";
import {convertor, PackDocument, UserDocument} from "./types";
import {useFirestoreDocumentsLive} from "../hooks/useFirstoreDocumentLive";

const firebaseConfig = {
    apiKey: "AIzaSyDRDou2s87M1Tk8TKj-ROvUMJtujNTfHb0",
    authDomain: "subtle-cubist-379522.firebaseapp.com",
    projectId: "subtle-cubist-379522",
    storageBucket: "subtle-cubist-379522.appspot.com",
    messagingSenderId: "849859822760",
    appId: "1:849859822760:web:b593b423acbc6986ceae9d"
};


const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
const firestore = getFirestore(app)
const storage = getStorage(app)
export default app

export function useAuth() {
    const [user, setUser] = React.useState<User>()

    useEffect(() => {
        const unsubscribeFromAuthStateChanged = onAuthStateChanged(auth, (_user) => {
            if (_user) {
                console.log("User token received!")
                setUser(_user)
            } else {
                setUser(undefined)
            }
        })

        return unsubscribeFromAuthStateChanged
    }, [])

    return user
}

export function signInWithEmail(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password)
}

export function listPacks(editableOnly = false) {
    // This function is written this way so that it does not prevent a page from loading.

    const [packs, setPacks] = React.useState<QueryDocumentSnapshot<PackDocument>[]>([])

    useEffect(() => {
        if (editableOnly) {
            getDocs(query(
                collection(firestore, "packs"),
                where("public", "==", false)
            ).withConverter(convertor<PackDocument>()))
                .then(docs => {
                    setPacks(docs.docs)
                })
        } else {
            getDocs(collection(firestore, "packs").withConverter(convertor<PackDocument>()))
                .then(docs => {
                    setPacks(docs.docs)
                })
        }

        return () => {

        }
    }, [])

    return {packs}
}

export function listPacksLive(editableOnly = false) {
    // This function is written this way so that it does not prevent a page from loading.

    return useFirestoreDocumentsLive(editableOnly ?
        query(
            collection(firestore, "packs"),
            where("public", "==", false)
        ) :
        collection(firestore, "packs")
    )
}

function wait(milliseconds: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

/*
    Signs the user in to an anonymous account, if they are not already signed in.
 */
export async function enforceSignIn() {
    if (!auth.currentUser) {
        await signInAnonymously(auth)
        await wait(3000)
    }
    return auth.currentUser
}

export async function setDisplayName(displayName: string) {
    await enforceSignIn()
    await updateProfile(auth.currentUser, {
        displayName
    })
}

export async function getUserData() {
    await enforceSignIn()
    console.log(auth.currentUser)

    let docs = await getDocs(
        query(
            collectionGroup(firestore, "users").withConverter(convertor<UserDocument>()),
            where("userID", "==", auth.currentUser.uid)
        )
    )
    console.log(docs)
    if (docs.size !== 1) {
        throw "Invalid response"
    }

    return docs.docs[0]
}

export async function uploadUserPFP(file): Promise<void> {
    return new Promise(async (resolve, reject) => {
        let form = new FormData()
        form.set("picture", file)
        fetch(`${Constants.expoConfig.extra.apiUrl}/api/v1/users/@me/profile/picture`, {
            method: "post",
            headers: {
                Authorization: "Bearer " + await auth.currentUser.getIdToken(),
            },
            body: form
        })
            .then(res => res.json())
            .then(res => {
                if (res.result === 0) {
                    resolve()
                } else {
                    reject(res)
                }
            })
            .catch(e => {
                console.log(e)
                reject(e)
            })
    })
}

//
// setInterval(()=> {
//     console.log(auth.currentUser)
// }, 3000)