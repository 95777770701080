import {NavigationContainer} from "@react-navigation/native";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import * as Linking from 'expo-linking';
import HomeScreen from "./src/pages/HomeScreen";
import LoginScreen from "./src/pages/LoginScreen";
import '@expo/match-media'
import AppLoading from 'expo-app-loading';
import {useFonts} from "expo-font";
import StartGame from "./src/pages/StartGame";
import SignUpScreen from "./src/pages/SignUpScreen";
import Index from "./src/pages/game";
import PackManager from "./src/pages/PackManager";
import React from "react";
import "./src/css/styles.css"


const Stack = createNativeStackNavigator()

export default function App() {
    let fontsLoaded = useFonts({
        RubikIso_400Regular: "https://fonts.gstatic.com/s/rubikiso/v1/x3dickHUfr-S4VAI4sABfPACvy_1BA.ttf"
    })

    if (fontsLoaded) {
        return (
            <>
                <NavigationContainer
                    linking={{
                        prefixes: [Linking.makeUrl('/')],
                        config: {
                            screens: {
                                Home: "/",
                                Login: "/login",
                                "Sign Up": "/signup",
                                "Start a new game": "/games/start",
                                "Game": "/games/lobby/:id",
                                "Pack Manager": "/account/packs",
                                "Pack Manager Specific": "/account/packs/:id",
                            }
                        },
                        subscribe(listener) {
                            const onReceiveURL = ({url}) => listener(url);
                            Linking.addEventListener("url", onReceiveURL);
                            // return () => Linking.removeEventListener("url", onReceiveURL)
                        },
                        getInitialURL() {
                            return Linking.getInitialURL().then(url => {
                                if (url) return url
                                return window.location.pathname
                            })
                        }
                    }}
                >
                    <Stack.Navigator
                        screenOptions={{headerShown: false}}
                    >
                        <Stack.Screen name="Home" component={HomeScreen}/>
                        <Stack.Screen name="Login" component={LoginScreen}/>
                        <Stack.Screen name="Sign Up" component={SignUpScreen}/>
                        <Stack.Screen name="Pack Manager" component={PackManager}/>
                        <Stack.Screen name="Pack Manager Specific" component={PackManager}/>
                        <Stack.Screen name="Start a new game" component={StartGame}/>
                        <Stack.Screen name="Game" component={Index}/>
                    </Stack.Navigator>
                </NavigationContainer>
            </>
        );
    }
    else {
        return (<AppLoading/>)
    }
}

export type RootStackParamList = {
    // "Set your username": {
    //     page: string, params?: string
    // }
    "Game": {"id": string},
    "Pack Manager": undefined,
    "Pack Manager Specific": {"id": string}
}