import {Dimensions, FlatList, ImageBackground, StyleSheet, Text, TextInput, View} from "react-native";
import {StatusBar} from "expo-status-bar";
import {colours} from "../../components/MainStyles";
import firebase, {useAuth} from "../../config/firebase";
import React, {useEffect, useState} from "react";
import WhiteCard from "../../components/WhiteCard";
import {setStringAsync as setClipboardStringAsync} from 'expo-clipboard';
import {DocumentSnapshot, getDoc, getFirestore, QueryDocumentSnapshot} from "firebase/firestore";
import {
    CardTypes,
    GamePlayerDocument,
    GamePlayerInventoryDocument,
    PackCardDocument,
    RoundStages
} from "../../config/types";
import {faCopy, faForward, faUser, faWifi} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import CardsBackgroundAnimation from "../../components/CardsBackgroundAnimation";
import "../../css/deck.css"
import LoadingModal from "../../components/LoadingModal";
import AsyncButton from "../../components/AsyncButton";
import Constants from 'expo-constants'
import socket, {setGameID} from "../../config/socket";
import WinnerDisplay from "../../components/WinnerDisplay";
import {formatText} from "../../components/FormatText";
import {useLiveGameData} from "../../hooks/useLiveGameData";
import type {StackScreenProps} from '@react-navigation/stack';
import {RootStackParamList} from "../../../App";
import BlackCard from "../../components/BlackCard";
import {SubmissionCardItem} from "./submissionCardItem";
import {populateBlackCard} from "./populateBlackCard";

const firestore = getFirestore(firebase)

interface MainDisplayInterface {
    backgroundType: "white" | "black",
    cards: {
        cardType: CardTypes,
        cardText: string,
        submissionId: string
    }[]
}

enum SubmitMode {
    AVAILABLE,
    RESUBMIT,
    UNAVAILABLE
}

export default function Index(props: StackScreenProps<RootStackParamList, "Game">) {
    const game = useLiveGameData(props.route.params.id)

    const [usedCards, setUsedCards] = useState<{
        [key: string]: { item: QueryDocumentSnapshot<GamePlayerInventoryDocument>, text: string }
    }>({})
    const [selectedCard, setSelectedCard] = useState<{ item: QueryDocumentSnapshot<GamePlayerInventoryDocument>, text: string }>(null)
    const skip = async () => {
        return new Promise<void>(async (resolve) => {
            fetch(`${Constants.expoConfig.extra.apiUrl}/api/v1/games/${props.route.params.id}/rounds/new`, {
                method: "GET",
                headers: {
                    Authorization: await user.getIdToken()
                }
            })
                .then(res => res.json())
                .then(res => {
                })
                .finally(() => resolve())
        })
    }
    const [submitMode, setSubmitMode] = useState<SubmitMode>(SubmitMode.AVAILABLE)
    const [winner, setWinner] = useState<DocumentSnapshot<GamePlayerDocument> | null>(null)
    const [usernameChange, setUsernameChange] = useState(false) // This is used to trigger a refresh when the username is changed
    const [showWinner, setShowWinner] = useState(false)
    const [currentBlackCard, setCurrentBlackCard] = useState<DocumentSnapshot<PackCardDocument<CardTypes.BLACK>>>()
    let user = useAuth()

    const IS_GAMEHOST = game.currentPlayer?.data().user.id === game.gameData?.data().gamehost.id
    const IS_CARDCZAR = game.currentPlayer?.id === game.currentRound?.data().card_czar.id

    useEffect(() => {
        if (game.status === 'success' && user) {
            setGameID(props.route.params.id)
            socket.connect()
        }
        return () => {
            socket.disconnect()
        }
    }, [game.status, user])

    useEffect(() => {
        const action = async () => {
            if (!game.currentRound) return
            setCurrentBlackCard(await getDoc(game.currentRound.data().black_card))

            if (!game.currentRound?.data().selected_submission) {
                setWinner(null)
                return
            }

            let submission = await getDoc(game.currentRound.data().selected_submission)
            let player = await getDoc(submission.data().user)
            setWinner(player)
        }
        action()
    }, [game.currentRoundSubmissions]);

    useEffect(() => {
        setTimeout(() => {
            setShowWinner(true)
        }, 5000)

        setTimeout(() => {
            setShowWinner(false)
        }, 10000)
        return () => {
            socket.disconnect()
        }
    }, [])

    return (
        <View style={{flexGrow: 1}}>
            <LoadingModal show={game.status === "joining"} onUsernameChange={() => {
                setUsernameChange(true)
            }}/>

            <WinnerDisplay player={winner}>
                <Text>
                    {formatText(
                        populateBlackCard(
                            currentBlackCard?.data().contents || "",
                            game.currentRoundSubmissions
                                .find(i => i.id === game.currentRound.data().selected_submission?.id)?.cardData
                                .map(card => {
                                    if (typeof card === "string") return card
                                    else card.data().contents
                                }) || []
                        )
                        || "Unknown card"
                    )}
                </Text>
                {
                    IS_GAMEHOST ?
                        <AsyncButton
                            style={{
                                padding: 5,
                                paddingLeft: 10,
                                flexDirection: "row"
                            }}
                            asyncOnPress={skip}
                        >
                            <div>
                                <FontAwesomeIcon icon={faForward} size={14} color={"#000"}/> Next Round
                            </div>
                        </AsyncButton> : undefined
                }
            </WinnerDisplay>

            <div style={{
                backgroundColor: colours[2],
                alignItems: 'center',
                justifyContent: 'center',
                padding: 10,
                flexGrow: 0,
                flexShrink: 0,
                paddingLeft: 20,
                paddingRight: 20,
                textAlign: "center"
            }}>
                <Text style={{fontSize: 16, color: "#fff"}}>Join lobby with
                    code: <strong>{game.gameData?.data().code}</strong></Text>
                <StatusBar style="auto"/>
            </div>

            <View
                style={{
                    flexGrow: 1,
                    overflow: "hidden"
                }}
            >
                <div
                    className="player_scoreboard"
                >
                    <FlatList
                        data={game.players.sort((a, b) => {
                            return 0 - (a.data().score - b.data().score)
                        })}
                        renderItem={({item}) => {
                            console.log(item)
                            return <PlayerScoreboardItem player={item}/>
                        }}
                        style={[{
                            // paddingRight: 20,
                            // paddingLeft: 20,
                            paddingTop: 10,
                            paddingBottom: 10,
                            flexGrow: 1
                        },
                            Dimensions.get("window").width < 480 ? {
                                paddingLeft: 20,
                                paddingRight: 20
                            } : undefined
                        ]}
                        ItemSeparatorComponent={() => <View style={{width: 10, height: 10}}/>}
                        extraData={game.players}
                        horizontal={Dimensions.get("window").width < 480}
                    />
                    {
                        IS_GAMEHOST && Dimensions.get("window").width >= 480 ?
                            <div className="player_scoreboard_button" style={{padding: 0}}>
                                <AsyncButton
                                    style={{
                                        padding: 5,
                                        paddingLeft: 10,
                                        flexDirection: "row"
                                    }}
                                    asyncOnPress={skip}
                                >
                                    <div>
                                        <FontAwesomeIcon icon={faForward} size={14} color={"#000"}/> Skip
                                    </div>
                                </AsyncButton>
                            </div> : undefined
                    }
                    <div className="player_scoreboard_button" onClick={() => {
                        setClipboardStringAsync(game.gameData?.data().code.toString())
                    }}>
                        <FontAwesomeIcon icon={faCopy} size={14} color={"#000"}/> {game.gameData?.data().code}
                    </div>
                </div>

                <View
                    style={{
                        flexGrow: 1,
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: game.currentRound?.data().stage === RoundStages.VOTING ? "#333" : "#aaa",
                        height: 10,
                        overflow: "hidden"
                    }}
                >
                    <CardsBackgroundAnimation cardType="white" style={{
                        opacity: game.currentRound?.data().stage === RoundStages.VOTING ? 0.25 : 0.75,
                        backgroundColor: colours[0]
                    }}/>
                    <View
                        style={{
                            flexGrow: 1,
                            alignItems: "center"
                        }}
                    >
                        {IS_CARDCZAR ?
                            <Text
                                style={{
                                    marginBottom: 10
                                }}
                            >YOU are the card czar!</Text> : undefined}
                        {
                            game.currentRound?.data().stage === RoundStages.CARD_SELECTION ?
                                <BlackCard text={currentBlackCard?.data().contents || ""} onBlankPress={(originalText, index) => {
                                    if (selectedCard) {
                                        usedCards[index] = {
                                            item: selectedCard.item,
                                            text: selectedCard.text
                                        }
                                        console.log(usedCards)
                                        setUsedCards(usedCards)
                                        setSubmitMode(Object.keys(usedCards).length >= currentBlackCard.data().contents.replace(/[^_]/g, "").length ?
                                            (game.currentPlayer?.data().submitted ? SubmitMode.RESUBMIT : SubmitMode.AVAILABLE) : SubmitMode.UNAVAILABLE
                                        )
                                        return selectedCard.text
                                    }
                                    else return originalText
                                }}/>:
                                <FlatList
                                    data={game.currentRoundSubmissions}
                                    keyExtractor={(item) => item.id}
                                    horizontal={true}
                                    style={{
                                        maxWidth: "75vw"
                                    }}
                                    scrollEnabled={true}
                                    renderItem={({item}) => {
                                        return <SubmissionCardItem
                                            item={item}
                                            gameID={game.gameData?.id}
                                            blackCard={currentBlackCard}
                                            enabled={
                                                (game.currentRound?.data().stage === RoundStages.VOTING &&
                                                (!game.currentRound?.data().selected_submission)) ||
                                                game.currentRound?.data().selected_submission.id === item.id
                                            }
                                        />
                                    }}
                                />
                        }

                        <View
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <AsyncButton
                                style={{
                                    paddingTop: 10,
                                    paddingLeft: 20,
                                    paddingBottom: 10,
                                    paddingRight: 20,
                                    opacity: submitMode === SubmitMode.UNAVAILABLE ? .5 : 1,
                                    backgroundColor: "#000",
                                    display: IS_CARDCZAR || game.currentRound?.data().stage === RoundStages.VOTING ? "none" : "flex"
                                }}

                                asyncOnPress={async () => {
                                    if (!(game.currentRound.data().stage == RoundStages.VOTING || Object.keys(usedCards).length < currentBlackCard?.data().contents.replace(/[^_]/g, "").length)) {
                                        // If statement is only used for protection. User's shouldn't be able to press button anyway when a round is not active
                                        console.log("USED CARDS:", usedCards)
                                        let data = Object.keys(usedCards).map(i => {
                                            return {index: i, card: usedCards[i].item.id, customText: usedCards[i].text}
                                        })
                                        console.log(data)
                                        data.sort((a, b) => {
                                            return a.index < b.index ? -1 : 1
                                        })
                                        console.log(data)
                                        //
                                        // let submission = await addDoc(collection(round.ref, "submissions"), {
                                        //     user: (await getUserData()).ref,
                                        //     cards: data.map(i => i.card.ref)
                                        // })
                                        // let player = players.find(player => player.id === playerID)
                                        // let playerData = player.data()
                                        // playerData.submitted = true
                                        // console.log(playerData)
                                        // setDoc(player.ref, playerData)

                                        let req = await fetch(`${Constants.expoConfig.extra.apiUrl}/api/v1/games/${props.route.params.id}/rounds/submissions/submit`, {
                                            method: "POST",
                                            headers: {
                                                Authorization: "Bearer " + await user.getIdToken(),
                                                "content-type": "application/json"
                                            },
                                            body: JSON.stringify(data)
                                        })
                                        let res = await req.json()
                                        setSubmitMode(SubmitMode.RESUBMIT)
                                        if (res.result === 0) setUsedCards({})
                                    }
                                }}
                            >
                                <Text
                                    style={{color: "#fff"}}>{submitMode === SubmitMode.RESUBMIT ? "Resubmit" : "Submit"}</Text>
                            </AsyncButton>

                            {IS_GAMEHOST && Dimensions.get("window").width < 480 ?
                                <View style={{marginLeft: 10}}><AsyncButton
                                    style={{
                                        paddingTop: 10,
                                        paddingLeft: 20,
                                        paddingBottom: 10,
                                        paddingRight: 20,
                                        backgroundColor: "#000",
                                    }}

                                    asyncOnPress={() => {
                                        return skip()
                                    }}
                                >
                                    <Text style={{color: "#fff"}}>Start next round</Text>
                                </AsyncButton></View> : undefined}
                        </View>
                    </View>
                </View>
                {/*  Show player deck  */}
                <div
                    tabIndex={1}
                    className="deck"
                    id="deck"
                >
                    <FlatList
                        data={game.inventory}
                        style={{
                            flexDirection: "row",
                            padding: 10,
                            width: "100%",
                            height: 250,
                        }}
                        renderItem={({item, index}) => {
                            return <InventoryCard
                                card={item}
                                swooshDelay={index}
                                onPress={(text) => {
                                    console.log("Card selected:", item)
                                    setSelectedCard({item, text})
                                }}
                            />
                        }}
                        persistentScrollbar={true}
                        horizontal={true}
                        keyExtractor={(item) => {
                            return item.id
                        }}
                    />
                </div>
            </View>
        </View>
    );
}


function InventoryCard(props: InventoryCardProps) {
    const [text, setText] = useState("")
    const [resText, setResText] = useState("")
    const [wildcard, setWildcard] = useState(props.card.data().wildcard)
    // const [wildcard, setWildcard] = useState(WildcardTypes.PICKUP)
    useEffect(() => {
        if (props.card.data().card) getDoc(props.card.data().card)
            .then(doc => {
                // @ts-ignore
                setText(doc.data().contents)
                setResText(doc.data().contents)
            })
        else {
            // Card is a blank card
            setText(null)
        }
    }, [])

    return <div
        onClick={() => {
            props.onPress(resText)
        }}
        style={{animationDelay: props.swooshDelay + "s"}}
        className="deck_card"
        draggable={true}
        onDragStart={(e) => {
            e.dataTransfer.setData("text/plain", '')

            // const dragText = document.createElement('span');
            // dragText.style.backgroundColor = "#fff"
            // // dragText.style.backgroundColor = "#fff"
            // dragText.style.zIndex = "5"
            // dragText.innerText = text || "your card here";
            // e.dataTransfer.setDragImage(dragText, 0, 0);
            // e.

            props.onPress(resText)
        }}
    >
        <WhiteCard text={text} wildcardType={wildcard} shareEnabled={true}>
            {text === null ? <TextInput
                placeholder="This is a blank card. Type something in here..."
                placeholderTextColor="#aaa"
                multiline={true}
                onChangeText={(t) => {
                    console.log("TEXT CHANGE");
                    setResText(t)
                    props.onPress(t)
                }}
                style={{
                    width: 200,
                    height: 296,
                    alignContent: "flex-start",
                    justifyContent: "flex-start"
                }}
            /> : undefined}
        </WhiteCard>
    </div>
}

interface InventoryCardProps {
    card: QueryDocumentSnapshot<GamePlayerInventoryDocument>,
    swooshDelay: number,

    onPress(text: string): void
}

const styles = StyleSheet.create({
    pack: {
        borderRadius: 5,
        padding: 20,
        backgroundColor: "#fff",
        marginBottom: 10
    },

    packname: {
        color: "#000"
    }
})

function PlayerScoreboardItem(props: PlayerScoreboardItemProps) {
    const [profileURI, setProfileURI] = useState(props.player.data().profile_picture || "")

    return <View
        style={{
            width: 60,
            // height: 50,
            // alignContent: "center",
            // justifyContent: "center",
            // marginRight: 10,
            alignSelf: "center"
        }}
    >
        {/*<View style={{*/}
        {/*    position: "absolute",*/}
        {/*    transform: [{rotate: "-45deg"}],*/}
        {/*    zIndex: 2*/}
        {/*}}>*/}
        {/*    <FontAwesomeIcon icon={faCrown} color={colours[1]}/>*/}
        {/*</View>*/}
        <View
            style={{
                borderRadius: 27,
                width: 60,
                borderWidth: 3,
                padding: 2,
                borderColor: props.player.data().submitted ? colours[3] : "transparent"
            }}
        >
            <ImageBackground
                source={{uri: profileURI}}
                style={{
                    // marginTop: 10,
                    borderRadius: 25,
                    width: 50,
                    height: 50,
                    backgroundColor: "#000",
                    justifyContent: "center",
                    overflow: "hidden",
                }}
                resizeMode="cover"
                // animated={false}
            >
                {profileURI ? undefined : <View
                    style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: colours[2]
                    }}
                >
                    <FontAwesomeIcon icon={faUser} size={24} color={"#fff"}/>
                </View>}
                {!props.player.data().connected ?
                    <View
                        style={{
                            backgroundColor: "rgba(0,0,0,0.75)",
                            flexGrow: 1,
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <FontAwesomeIcon icon={faWifi} style={{color: "#fff"}}/>
                    </View>
                    : <View
                        style={{
                            backgroundColor: "rgba(0,0,0,0.75)",
                            flexGrow: 1,
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <Text style={{color: "#fff"}}>{props.player.data().score || 0}</Text>
                    </View>
                }
            </ImageBackground>
        </View>

        <Text style={{
            textAlign: "center",
            backgroundColor: props.player.data().submitted ? colours[3] : "#000",
            color: "#fff",
            fontWeight: "100",
            marginTop: -10,
            borderRadius: 5,
            width: "100%",
            zIndex: 2
        }}>{props.player.data().username}</Text>
    </View>
}

interface PlayerScoreboardItemProps {
    player: QueryDocumentSnapshot<GamePlayerDocument>
}

// class WebSocketManager {
//     private ws: WebSocket
//     private readonly getToken: () => Promise<string>
//     private _disconnect = false
//     private gameid: string
//
//     constructor(gameid, getToken: () => Promise<string>) {
//         this.getToken = getToken
//         this.gameid = gameid
//     }
//
//     connect() {
//         // Connect to the websocket server
//         this.onConnect.bind(this)
//
//         this.ws = new WebSocket(Constants.expoConfig.extra.wsUrl)
//         this.ws.onopen = () => {
//             this.onConnect()
//         }
//         this.ws.onclose = () => {
//             if (!this._disconnect) setTimeout(() => {
//                 this.connect()
//             }, 1000)
//         }
//     }
//
//     disconnect() {
//         this._disconnect = true
//         this.ws.close()
//     }
//
//     private async onConnect() {
//         console.log("Connected to websocket server!")
//         this.ws.send(JSON.stringify({
//             type: 0,
//             // @ts-ignore
//             token: await this.getToken(),
//             gameid: this.gameid
//         }))
//     }
// }