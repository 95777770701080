import {TouchableOpacity} from "react-native";
import Constants from "expo-constants";
import {CardTypes, PackCardDocument, RoundStages} from "../../config/types";
import WhiteCard from "../../components/WhiteCard";
import React from "react";
import {ExtraSubmissionInfo} from "../../hooks/useLiveGameData";
import {useAuth} from "../../config/firebase";
import {populateBlackCard} from "./populateBlackCard";
import {DocumentSnapshot} from "firebase/firestore";

interface ISubmissionCardItem {
    item: ExtraSubmissionInfo,
    blackCard: DocumentSnapshot<PackCardDocument<CardTypes.BLACK>>,
    gameID: string,
    enabled: boolean
}

export function SubmissionCardItem(props: ISubmissionCardItem) {
    const user = useAuth()

    return <div
        className={"maindisplay_card"}
    >
        <TouchableOpacity
            onPress={async () => {
                fetch(`${Constants.expoConfig.extra.apiUrl}/api/v1/games/${props.gameID}/rounds/cardczar_select`, {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + await user.getIdToken(),
                        "content-type": "application/json"
                    },
                    body: JSON.stringify({
                        selected_submission: props.item.id
                    })
                })
            }}
            disabled={!props.enabled}
            style={{
                opacity: props.enabled ? 1 : 0.5
            }}
        >
            <WhiteCard text={
                props.blackCard ?
                    populateBlackCard(props.blackCard.data().contents, props.item.cardData.map(card => {
                        if (typeof card === "string") return card
                        else return card.data().contents
                    })):
                    "Waiting for data..."
            } shareEnabled={true}/>
        </TouchableOpacity>
    </div>
}