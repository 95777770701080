import {Dimensions, StyleSheet, Text, TextInput, TouchableOpacity, View} from "react-native";
import {StatusBar} from "expo-status-bar";
import NavWrapper from "../components/NavWrapper";
import {styles as MainStyles} from "../components/MainStyles";
import CardsBackgroundAnimation from "../components/CardsBackgroundAnimation";
import {useEffect, useState} from "react";
import firebase, {signInWithEmail, useAuth} from "../config/firebase";
import {createUserWithEmailAndPassword, getAuth} from "firebase/auth"
import {getFirestore, addDoc, collection} from "firebase/firestore";

const firestore = getFirestore(firebase)

export default function SignUpScreen(props: any) {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [signingIn, setSigningIn] = useState(false)
    const [loginButtonText, setLoginButtonText] = useState("Sign up")
    const user = useAuth()
    useEffect(() => {
        if (user) props.navigation.navigate("Home")
    }, [])

    return (
        <NavWrapper
            navigation={props.navigation}
            style={{flexGrow: 1}}
        >
            <View style={{
                flexGrow: 1,
                backgroundColor: "#aaa",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <CardsBackgroundAnimation style={{opacity: .5}} cardType="black"/>
                <View
                    style={{
                        padding: 20,
                        backgroundColor: "#fff",
                        borderRadius: 5
                    }}
                >
                    <h1 style={{fontFamily: "RubikIso_400Regular"}}>Sign Up</h1>
                    <TextInput style={MainStyles.textInput} placeholder="Email" onChangeText={(v) => setEmail(v)}/>
                    <TextInput style={MainStyles.textInput} secureTextEntry placeholder="Password"
                               onChangeText={(v) => setPassword(v)}/>
                    <TextInput style={MainStyles.textInput} secureTextEntry placeholder="Confirm password"
                               onChangeText={(v) => setPassword(v)}/>
                    <TouchableOpacity style={[MainStyles.primaryButton, {
                        marginLeft: 0,
                        marginTop: 10,
                        alignItems: "center",
                        opacity: (!email || !password || signingIn) ? 0.5 : 1
                    }]}
                                      disabled={!email || !password || signingIn}
                                      onPress={() => {
                                          let user
                                          setSigningIn(true)
                                          setLoginButtonText("Signing up...")
                                          createUserWithEmailAndPassword(getAuth(firebase), email, password)
                                              .then((u) => {
                                                  user = u
                                                  return signInWithEmail(email, password)
                                                  // props.navigation.navigate("Home")
                                              })
                                              .then(() => {
                                                  console.log(user.user.uid)
                                                  return addDoc(collection(firestore, "users"), {
                                                      userID: user.user.uid
                                                  })
                                              })
                                              .then(
                                                  props.navigation.navigate("Home")
                                              )
                                              .catch(e => {
                                                  console.log(e)
                                                  setLoginButtonText("Invalid email/password!")
                                                  setTimeout(() => {
                                                      setSigningIn(false)
                                                      setLoginButtonText("Login")
                                                  }, 3000)
                                              })
                                      }}
                    >
                        <Text style={MainStyles.primaryButtonText}>
                            {loginButtonText}
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        </NavWrapper>
    );
}
