import {
    Button,
    Dimensions,
    ImageBackground,
    ScrollView,
    StyleSheet,
    Text,
    TextInput,
    TouchableOpacity,
    View
} from "react-native";
import {StatusBar} from "expo-status-bar";
import NavWrapper from "../components/NavWrapper";
import {styles as MainStyles, colours} from "../components/MainStyles";
import LoadingModal from "../components/LoadingModal";
import React, {useEffect, useState} from "react";
import {enforceSignIn, uploadUserPFP, useAuth} from "../config/firebase";
import Constants from "expo-constants";
import {faArrowRight, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {useMediaQuery} from "react-responsive";

export default function HomeScreen(props: any) {
    const user = useAuth()
    const [file, setFile] = useState(null)
    const [joinGameCode, setJoinGameCode] = useState("")

    const handleFileChange = (e) => {
        setFile(e.target.files[0])
    }

    const isMobile = useMediaQuery({
        maxDeviceWidth: 1224
    });

    useEffect(() => {
        setJoinGameCode("") // Reset the game code each time that this page is loaded
    }, [])

    return (
        <NavWrapper navigation={props.navigation} style={{flexGrow: 1}}>
            <ScrollView
                style={{
                    height: 10,
                    flexGrow: 1
                }}
            >
                <ImageBackground
                    style={[MainStyles.container, {
                        backgroundColor: colours[4],
                        justifyContent: "flex-start",
                        flexDirection: "row",
                        minHeight: 400,
                        padding: 0
                    }]}
                    source={require("../../assets/homepage_background.jpg")}
                >
                    {/*{isMobile ? undefined : <View*/}
                    {/*    style={{*/}
                    {/*        width: 200,*/}
                    {/*        height: 200,*/}
                    {/*        backgroundColor: "#fff",*/}
                    {/*        marginRight: 50*/}
                    {/*    }}*/}
                    {/*>*/}

                    {/*</View>}*/}
                    <View
                        style={{
                            backgroundColor: "rgba(0,0,0,0.75)",
                            maxWidth: "100vw",
                            height: "100%",
                            padding: 40
                        }}
                    >
                        <Text
                            style={{color: "#fff"}}
                        >Welcome to</Text>
                        <Text
                            style={[MainStyles.navBarHeader, {
                                color: colours[1],
                                flexGrow: 0
                            }]}
                        >Unholy & Twisted (ALPHA)</Text>
                        <Text
                            style={{color: "#fff", flexGrow: 1}}
                        >Get Your Sick and Twisted Fix with Twisted & Unholy Online!</Text>

                        <Text
                            style={{
                                color: "#fff",
                                marginTop: 24
                            }}
                        >Ready to play?</Text>
                        <View
                            style={{
                                flexDirection: "row",
                                marginTop: 10,
                                alignItems: "center",
                                flexWrap: "wrap",
                                flexGrow: 0,
                                width: "100%"
                            }}
                        >
                            <TextInput
                                style={{
                                    padding: 9,
                                    borderTopLeftRadius: 5,
                                    borderBottomLeftRadius: 5,
                                    backgroundColor: "#fff",
                                    width: 200,
                                }}
                                placeholder="Enter lobby code here..."
                                value={joinGameCode}
                                maxLength={6}
                                editable={joinGameCode !== "disabled"}
                                onChangeText={(v) => {
                                    setJoinGameCode(v.replace(/[^0-9]/g, ''))
                                }}
                            />
                            <TouchableOpacity
                                style={[
                                    MainStyles.primaryButton,
                                    joinGameCode.length < 6 ? MainStyles.primaryButtonDisabled : undefined,
                                    {
                                        height: "100%",
                                        marginLeft: 0,
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                    }
                                ]}
                                onPress={async () => {
                                    let code = joinGameCode
                                    setJoinGameCode("disabled")


                                    // Perform a join request
                                    let game_join_req = await fetch(`${Constants.expoConfig.extra.apiUrl}/api/v1/games/${code}/join`, {
                                        method: "post",
                                        headers: {
                                            Authorization: "Bearer " + await (await enforceSignIn()).getIdToken(),
                                            Accept: "application/json"
                                        }
                                    })
                                    let game_data: {
                                        result: number,
                                        playerID?: string,
                                        gameID?: string
                                    } = await game_join_req.json()
                                    if (game_data.result !== 0 || !game_data.playerID || !game_data.gameID) {
                                        alert("Oh no! We couldn't join you to that game. Please try again.")
                                        return
                                    }
                                    props.navigation.navigate("Game", {id: game_data.gameID})
                                }}
                            >
                                <FontAwesomeIcon icon={faArrowRight} style={MainStyles.primaryButtonText}/>
                            </TouchableOpacity>

                            <Text
                                style={{
                                    margin: 10,
                                    color: "#fff"
                                }}
                            >
                                or
                            </Text>
                            <TouchableOpacity style={[MainStyles.primaryButton,
                                {
                                    marginLeft: 0
                                }]}
                                              onPress={() => {
                                                  props.navigation.navigate("Start a new game")
                                              }}
                            >
                                <Text style={MainStyles.primaryButtonText}>Start a game!</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <StatusBar style="auto"/>
                </ImageBackground>
                <View style={[MainStyles.container, {
                    flexDirection: "row",
                    minHeight: 400
                }]}>
                    <View
                        style={{
                            flexGrow: 1,
                            width: 10
                        }}
                    >
                        <Text
                            style={[MainStyles.navBarHeader, {
                                color: colours[4]
                            }]}
                        >Legal Disclaimer</Text>

                        <Text
                            style={{
                                marginTop: 24,
                                maxWidth: "100%",
                                flexWrap: "wrap",
                                color: "#fff"
                            }}
                        >
                            This is an 18+ game! If you are under the age of 18, or are easily offended, this is not the
                            game for you.
                        </Text>

                        <Text
                            style={{
                                marginTop: 24,
                                maxWidth: "100%",
                                flexWrap: "wrap",
                                color: "#fff"
                            }}
                        >
                            Unholy & Twisted is an online copy of the famous card game Cards Against Humanity
                            (available at <a href="https://cardsagainsthumanity.com">cardsagainsthumanity.com</a>). This
                            project is distributed under a <a href="https://creativecommons.org/licenses/by-nc-sa/3.0/">Creative
                            Commons License CC BY-NC-SA 3.0</a>.
                        </Text>
                    </View>
                    <View
                        style={{
                            // width: 200,
                            // height: 200,
                            // backgroundColor: "#fff",
                            marginLeft: 50,
                            position: isMobile ? "absolute" : undefined,
                            right: isMobile ? 20 : undefined,
                            opacity: isMobile ? .1 : 1
                        }}
                    >
                        <FontAwesomeIcon icon={faTriangleExclamation} size={128} style={{color: "#fff"}}/>
                    </View>
                </View>
                <View style={[MainStyles.container, {
                    backgroundColor: colours[4],
                    flexDirection: "row",
                    minHeight: 400
                }]}>
                    {isMobile ? undefined : <View
                        style={{
                            width: 200,
                            height: 200,
                            backgroundColor: "#fff",
                            marginRight: 50
                        }}
                    >

                    </View>}
                    <View
                        style={{
                            flexGrow: 1,
                            width: 10
                        }}
                    >
                        <Text
                            style={[MainStyles.navBarHeader, {
                                color: colours[1]
                            }]}
                        >Build your collections!</Text>

                        <Text
                            style={{
                                marginTop: 24,
                                maxWidth: "100%",
                                flexWrap: "wrap",
                                color: "#fff"
                            }}
                        >
                            Ready to spice your game up? Use our pack creator tool to create and share your own card
                            packs!
                        </Text>

                        <Text
                            style={{
                                marginTop: 24,
                                maxWidth: "100%",
                                flexWrap: "wrap",
                                color: "#fff"
                            }}
                        >
                            Share your nerdy little secrets with your friends, and have fun!
                        </Text>
                    </View>
                    <StatusBar style="auto"/>
                </View>
                <View style={[MainStyles.container, {
                    padding: 20,
                    alignItems: "flex-start"
                }]}>
                    <Text
                        style={{color: "#fff"}}
                    >
                        <Text
                            style={{
                                fontWeight: "bold",
                                marginRight: 5,
                                color: "#fff"
                            }}
                        >
                            Unholy & Twisted is licensed under;
                        </Text>
                        <a href="https://creativecommons.org/licenses/by-nc-sa/3.0/">Creative
                            Commons License CC BY-NC-SA 3.0</a>
                    </Text>
                </View>
            </ScrollView>
        </NavWrapper>
    )
}
