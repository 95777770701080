import {View, Text, TouchableHighlight, TouchableOpacity} from "react-native";
import {colours, styles as MainStyles} from "./MainStyles"
import React, {useEffect, useRef, useState} from "react";
import {faDownload} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import html2canvas from "html2canvas";
import * as Sharing from "expo-sharing"

export default function BlackCard(props: CardInterface) {
    const shot = useRef()
    let split = (props.text || "").split("_")

    return <View
        style={[MainStyles.card, {backgroundColor: "black", height: props.dynamicHeight ? "" : "3.5in"}]}
        ref={shot}
    >
        {props.children ? props.children : <Text style={{color: "#fff", flexWrap: "wrap", flexDirection: "row"}}>{split.map((item, index) => {
            if (index !== split.length - 1 || split.length === 1) {
            return [item, <BlankBox
            index={index}
            onPress={props.onBlankPress}
            />]
        } else return [item]
        })}</Text>}
        <TouchableOpacity
            style={{position: "absolute", bottom: 20, right: 20}}
            onPress={() => {
                html2canvas(shot.current).then(canvas => {
                    let a = document.createElement("a")
                    a.href = canvas.toDataURL("image/png")
                    a.download = "card.png"
                    a.target = "_blank"
                    a.click()
                    console.log(canvas)
                })
            }}
        >
            <FontAwesomeIcon icon={faDownload} style={{color: "#fff"}}/>
        </TouchableOpacity>
    </View>
}

interface CardInterface {
    text?: string,
    children?: React.ReactNode
    dynamicHeight?: true,

    onBlankPress?(string, number): Promise<string> | string
}

function BlankBox(props: BlankBoxProps) {
    const [text, setText] = useState("blank")
    const [isDraggingOver, setIsDraggingOver] = useState(false)

    return <div
        style={{
            display: "inline",
            cursor: "pointer"
        }}
        onClick={async () => {
            if (props.onPress) setText(await props.onPress(text, props.index))
        }}
        onDragOver={(e) => {
            e.preventDefault()
            setIsDraggingOver(true)
        }}
        onDragExit={(e) => {
            e.preventDefault()
            setIsDraggingOver(false)
        }}
        onDrop={async (e) => {
            e.preventDefault()
            setIsDraggingOver(false)
            if (props.onPress) setText(await props.onPress(text, props.index))
        }}
    >
        <Text
            style={{textDecorationStyle: "solid", textDecorationLine: "underline", color: "#999", backgroundColor: isDraggingOver ? colours[2] : undefined}}
        >{text}</Text>
    </div>
}

interface BlankBoxProps {
    index: number,

    onPress?(string, number): Promise<string> | string
}