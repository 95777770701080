import {collection as firestore_collection, Firestore, DocumentData, QueryDocumentSnapshot, DocumentReference} from "firebase/firestore";

export enum CardTypes {
    WHITE= "white",
    BLACK = "black"
}

export enum WildcardTypes {
    NONE,
    PICKUP,
    SHUFFLE,
    SKIP,
    BLANK
}

export enum RoundStages {
    CARD_SELECTION,
    VOTING
}

export interface UserDocument {
    userID: string
}

export const convertor = <T>() => ({
    toFirestore: (data: T) => data,
    fromFirestore: (snap: QueryDocumentSnapshot) => snap.data() as T
})

export interface GameDocument {
    deck: [],
    gamehost: DocumentReference<UserDocument>
    packs: GameDocumentPack[],
    round: DocumentReference<GameRoundDocument> | null,
    card_types: WildcardTypes[],
    active: boolean,
    code?: number
}

export interface GameDocumentPack {
    pack_size_black: number,
    pack_size_white: number,
    ref: DocumentReference<PackDocument>
}

export interface PackDocument {
    pack_name: string,
    pack_owner: DocumentReference<UserDocument> | DocumentReference<UserDocument>[],
    pack_size_black: number,
    pack_size_white: number,
    public: boolean
}

export interface PackCardDocument<T extends CardTypes> {
    contents: string,
    pack: DocumentReference<PackDocument>
    pick: number,
    type: T
}

export interface GameRoundDocument {
    black_card: DocumentReference<PackCardDocument<CardTypes.BLACK>>,
    white_card?: DocumentReference<PackCardDocument<CardTypes.WHITE>>,
    block_submissions: boolean,
    card_czar: DocumentReference<GamePlayerDocument>,
    selected_submission?: DocumentReference<GameSubmissionDocument>,
    stage: RoundStages
}

export interface GameSubmissionDocument {
    cards: GameSubmissionDocumentCard[],
    user: DocumentReference<GamePlayerDocument>
}
export interface GameSubmissionDocumentCard {
    card: DocumentReference<PackCardDocument<CardTypes.WHITE>>,
    index: number,
    customText?: string
}


export interface GamePlayerDocument {
    connected: boolean,
    profile_picture?: string,
    submitted: boolean,
    turns: number,
    username: string,
    score: number,
    user: DocumentReference<UserDocument>
}

export interface GamePlayerInventoryDocument {
    card: DocumentReference<PackCardDocument<CardTypes.WHITE>>,
    wildcard: WildcardTypes
}