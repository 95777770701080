import {useEffect, useState} from "react";
import {
    getDoc,
    getFirestore,
    doc,
    onSnapshot,
    DocumentReference,
    Query,
    DocumentSnapshot,
    QueryDocumentSnapshot, collection, getDocs
} from "firebase/firestore";
import firebase, {getUserData, useAuth} from "../config/firebase";
import Constants from "expo-constants";
import {
    CardTypes,
    convertor,
    GameDocument,
    GamePlayerDocument,
    GamePlayerInventoryDocument,
    GameRoundDocument, GameSubmissionDocument, PackCardDocument
} from "../config/types";
import {getAuth} from "firebase/auth";

const firestore = getFirestore(firebase)

export interface ExtraSubmissionInfo extends QueryDocumentSnapshot<GameSubmissionDocument> {
    cardData: (DocumentSnapshot<PackCardDocument<CardTypes.WHITE>> | string)[]
}

interface LiveGameData {
    status: "joining" | "success" | "failed",
    gameData: DocumentSnapshot<GameDocument>,
    currentPlayer: DocumentSnapshot<GamePlayerDocument> | null,
    currentRound: DocumentSnapshot<GameRoundDocument> | null,
    currentRoundSubmissions: ExtraSubmissionInfo[]
    players: QueryDocumentSnapshot<GamePlayerDocument>[],
    inventory: QueryDocumentSnapshot<GamePlayerInventoryDocument>[],
}

export function useLiveGameData(gameID: string): LiveGameData {
    const [status, setStatus] = useState<LiveGameData["status"]>("joining")
    const [game, setGame] = useState<LiveGameData["gameData"]>()
    const [currentPlayer, setCurrentPlayer] = useState<LiveGameData["currentPlayer"]>()
    const [currentRound, setCurrentRound] = useState<LiveGameData["currentRound"]>()
    const [currentRoundSubmissions, setCurrentRoundSubmissions] = useState<LiveGameData["currentRoundSubmissions"]>([])
    const [players, setPlayers] = useState<LiveGameData["players"]>([])
    const [inventory, setInventory] = useState<LiveGameData["inventory"]>([])
    const user = useAuth()

    useEffect(() => {
        let unsubscribeArray: (() => void)[] = []
        let unsubscribeRound: () => void | null = null
        let unsubscribeSubmissions: () => void | null = null

        const onRoundChange = (
            snapshot: DocumentSnapshot<GameRoundDocument>,
            setRound: (d: DocumentSnapshot<GameRoundDocument>) => void,
            setSubmissions: (d: ExtraSubmissionInfo[]) => void
        ) => {
            setRound(snapshot)
            if (unsubscribeSubmissions) unsubscribeSubmissions()
            unsubscribeSubmissions = firestoreQuerySnapshot<GameSubmissionDocument>(
                collection(snapshot.ref, "submissions"),
                async (i) => {
                    let items = i as unknown as ExtraSubmissionInfo[]
                    for (let item of items) {
                        // Get cards in the submission
                        let cards = await Promise.all(item.data().cards.map(i => {
                            if (i.customText) return i.customText
                            else return getDoc(i.card)
                        }))
                        item.cardData = cards
                    }
                    setCurrentRoundSubmissions(items)
                }
            )
        }

        const setup = async () => {
            try {
                const token = (await getAuth(firebase)).currentUser?.getIdToken()
                if (!token) throw new Error("User not signed in")

                const userData = await getUserData()
                const game = await getDoc(doc(firestore, "games", gameID))
                const joinResult = await sendLobbyJoinRequest(gameID, await token)
                const playerDoc = doc(game.ref, "players", joinResult.playerID)

                console.log("Registering listeners!")
                unsubscribeArray.push(firestoreDocSnapshot<GameDocument>(game.ref,
                    (d) => {
                        setGame(d)
                        if (!d.data().round) return
                        if (unsubscribeRound) unsubscribeRound()

                        unsubscribeRound = firestoreDocSnapshot<GameRoundDocument>(
                            d.data().round,
                            (snapshot) => onRoundChange(snapshot, setCurrentRound, setCurrentRoundSubmissions)
                        )
                    })
                )
                unsubscribeArray.push(firestoreDocSnapshot<GamePlayerDocument>(playerDoc,
                    (d) => setCurrentPlayer(d))
                )
                unsubscribeArray.push(firestoreQuerySnapshot<GamePlayerInventoryDocument>(
                    collection(playerDoc, "inventory"),
                    (d) => setInventory(d))
                )
                unsubscribeArray.push(firestoreQuerySnapshot<GamePlayerDocument>(
                    collection(game.ref, "players"),
                    (d) => setPlayers(d))
                )
                setStatus("success")
            } catch (e) {
                console.error("Fetching live game data failed")
                console.error(e)
                setStatus("failed")
            }
        }
        if (user) setup()

        return () => {
            for (let item of unsubscribeArray) item()
            if (unsubscribeRound) unsubscribeRound()
            if (unsubscribeSubmissions) unsubscribeSubmissions()
        }
    }, [user]);

    return {status, gameData: game, currentPlayer, currentRound, currentRoundSubmissions, players, inventory}
}

function firestoreDocSnapshot<D>(documentReference: DocumentReference, valueSetter: (data: DocumentSnapshot<D>) => void) {
    return onSnapshot(
        documentReference.withConverter(convertor<D>()),
        (snapshot) => valueSetter(snapshot)
    )
}

function firestoreQuerySnapshot<D>(query: Query, valueSetter: (data: QueryDocumentSnapshot<D>[]) => void) {
    return onSnapshot(
        query.withConverter(convertor<D>()),
        (snapshot) => valueSetter(snapshot.docs)
    )
}

export async function sendLobbyJoinRequest(gameID: string, token: string): Promise<{ playerID: string }> {
    const res = await fetch(`${Constants.expoConfig.extra.apiUrl}/api/v1/games/${gameID}/join`, {
        method: "post",
        headers: {
            Authorization: "Bearer " + token,
            Accept: "application/json"
        }
    })
    const data = await res.json()
    if (data.result !== 0) throw new Error("Server denied attempt to join the lobby")
    return data
}