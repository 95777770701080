import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {colours, styles as MainStyles} from "./MainStyles"
import html2canvas from "html2canvas";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {faDownload, faForward, faPlus, faShuffle, faStar, faVolumeHigh} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useRef, useState} from "react";
import {WildcardTypes} from "../config/types";
import {formatText} from "./FormatText";

export default function WhiteCard(props: CardInterface) {
    const shot = useRef()
    const speak_div = useRef<HTMLDivElement>()
    const [downloading, setDownloading] = useState(false)
    // props.wildcardType = WildcardTypes.PICKUP
    useEffect(() => {
        if (downloading) {
            html2canvas(shot.current).then(canvas => {
                let a = document.createElement("a")
                a.href = canvas.toDataURL("image/png")
                a.download = "card.png"
                a.target = "_blank"
                a.click()
                console.log(canvas)
            })
            setDownloading(false)
        }
    }, [downloading])
    return <div
        ref={speak_div}
    >
        <View ref={shot} style={[MainStyles.card, {
            backgroundColor: props.wildcardType ? colours[2] : "white",
            height: props.dynamicHeight ? "" : "3.5in"
        }]}>
            <Text
                style={{
                    fontWeight: props.wildcardType ? "bold" : "normal",
                    color: props.wildcardType ? "#fff" : "#000",
                }}
            >{formatText(props.text || "")}</Text>
            {props.wildcardType ?
                <View
                    style={{
                        // position: "absolute",
                        // width: 200,
                        // height: 296,
                        // alignItems: "center",
                        // justifyContent: "center"
                    }}
                >
                    {props.wildcardType === WildcardTypes.BLANK ?
                        <Text style={{color: "#fff"}}><FontAwesomeIcon icon={faStar} style={extraStyles.wildcard} size={14}/> Blank
                            card</Text> :
                        undefined
                    }
                    {props.wildcardType === WildcardTypes.SKIP ?
                        <Text style={{color: "#fff"}}><FontAwesomeIcon icon={faForward} style={extraStyles.wildcard} size={14}/> Skip to voting</Text> :
                        undefined
                    }
                    {props.wildcardType === WildcardTypes.SHUFFLE ?
                        <Text style={{color: "#fff"}}><FontAwesomeIcon icon={faShuffle} style={extraStyles.wildcard} size={14}/> Shuffle your
                            hand</Text> :
                        undefined
                    }
                    {props.wildcardType === WildcardTypes.PICKUP ?
                        <Text style={{color: "#fff"}}><FontAwesomeIcon icon={faPlus} style={extraStyles.wildcard} size={14}/> Pick up 5 additional cards</Text> :
                        undefined
                    }
                </View>
                : undefined}
            {props.children}
            {props.shareEnabled && !downloading ? [<TouchableOpacity
                    style={{
                        position: "absolute",
                        bottom: 20,
                        right: 20
                    }}
                    onPress={() => {
                        setDownloading(true)
                    }}
                >
                    <FontAwesomeIcon icon={faDownload} style={{color: "#000"}}/>
                </TouchableOpacity>,
                    <TouchableOpacity
                        style={{
                            position: "absolute",
                            bottom: 20,
                            right: 40
                        }}
                        onPress={() => {
                            window.speechSynthesis.speak(new SpeechSynthesisUtterance(speak_div.current.innerText))
                        }}
                    >
                        <FontAwesomeIcon icon={faVolumeHigh} style={{color: "#000"}}/>
                    </TouchableOpacity>]

                : undefined}
            <View
                style={{
                    position: "absolute",
                    bottom: 20,
                    left: 20,
                    display: downloading ? "flex" : "none"
                }}
            >
                <Text style={{fontSize: 10}}>Play now at<br/>
                    <strong>unholyandtwisted.com</strong>
                </Text>
            </View>
        </View>
    </div>
}

interface CardInterface {
    children?: React.ReactNode
    text?: string,
    dynamicHeight?: true,

    wildcardType?: number,
    shareEnabled?: boolean
}

const extraStyles = StyleSheet.create({
    wildcard: {opacity: .5, color: "#fff"}
})