import {StyleSheet, Text} from "react-native";
import React from "react";

export function formatText(text: string) {
    const parts = text.split("**")
    return parts.map((part, index) =>
        (index % 2 === 0) ? part :
            <Text style={bold.bold}>{part}</Text>
    )
}

const bold = StyleSheet.create({
    bold: {
        fontWeight: "bold"
    }
})